export const ULYSSEUS_INSTITUTIONS_DATA = [
	{
		shortName: "USE",
		name: "University of Seville (USE)",
		slug: "university-of-seville-use",
		founded: 1505,
		cityCountry: "Seville, Spain",
		Language: "Spanish",
		Students: 69876,
		facultiesAndSchools: 32,
		RessearchGroups: 574,
		imgPath:
			"/static/images/frontpage/logo-institution/institution-sevilla.svg",
		link: "https://www.us.es",
	},
	{
		shortName: "UNIGE",
		name: "University of Genoa (UniGe)",
		slug: "university-of-genoa-unige",
		founded: 1481,
		cityCountry: "Genoa, Italy",
		Language: "Italian",
		Students: 34590,
		facultiesAndSchools: 22,
		RessearchGroups: 158,
		imgPath:
			"/static/images/frontpage/logo-institution/institution-genoa.svg",
		link: "https://unige.it",
	},
	{
		shortName: "UNICA",
		name: `Université Côte d’Azur (UniCA)`,
		slug: "universite-cote-dazur-unica",
		founded: 2020,
		cityCountry: "Nice, France",
		Language: "French",
		Students: 30853,
		facultiesAndSchools: 21,
		RessearchGroups: 224,
		imgPath:
			"/static/images/frontpage/logo-institution/institution-unica.svg",
		link: "https://univ-cotedazur.fr",
	},
	{
		shortName: "TUKE",
		name: "Technical University of Košice (TUKE)",
		slug: "technical-university-of-koice-tuke",
		founded: 1952,
		cityCountry: "Kosice, Slovakia",
		Language: "Slovak",
		Students: 11021,
		facultiesAndSchools: 9,
		RessearchGroups: 67,
		imgPath:
			"/static/images/frontpage/logo-institution/institution-kosice.svg",
		link: "https://www.tuke.sk",
	},
	{
		shortName: "TUKE",
		name: "Management Centre Innsbruck (MCI)",
		slug: "management-centre-innsbruck-mci",
		founded: 1995,
		cityCountry: "Innsbruck, Austria",
		Language: "German",
		Students: 3550,
		facultiesAndSchools: 15,
		RessearchGroups: 13,
		imgPath:
			"/static/images/frontpage/logo-institution/institution-mci.svg",
		link: "https://www.mci.edu",
	},
	{
		shortName: "HH",
		name: "Haaga-Helia University of Applied Sciences (HH)",
		slug: "haaga-helia-university-of-applied-sciences-hh",
		founded: 2007,
		cityCountry: "Helsinki, Finland",
		Language: "Finnish",
		Students: 11000,
		facultiesAndSchools: 6,
		RessearchGroups: 4,
		imgPath:
			"/static/images/frontpage/logo-institution/institution-haaga-helia.svg",
		link: "https://www.haaga-helia.fi",
	},
	{
		shortName: "WWU",
		name: "University of Münster (WWU)",
		slug: "university-of-munster-wwu",
		founded: 1780,
		cityCountry: "Münster, Germany",
		Language: "German",
		Students: 44585,
		facultiesAndSchools: 15,
		RessearchGroups: 618,
		imgPath:
			"/static/images/frontpage/logo-institution/institution-unims.svg",
		link: "https://www.uni-muenster.de",
	},
	{
		shortName: "UCG",
		name: "University of Montenegro (UCG)",
		slug: "university-of-montenegro-ucg",
		founded: 1974,
		cityCountry: "Podgorica, Montenegro",
		Language: "Montenegrin",
		Students: 20000,
		facultiesAndSchools: 23,
		RessearchGroups: 30,
		imgPath:
			"/static/images/frontpage/logo-institution/institution-montenegro.svg",
		link: "https://www.ucg.ac.me",
	},
];

export const COMPONENTS_NAMES = [
	{
		id: 0,
		name: "home",
		imgPath: "home",
	},
	{
		id: 1,
		name: "Institutions",
		imgPath: "building",
	},
];
