import React, { useState } from "react";
import { COMPONENTS_NAMES } from "../../config/metadata";
import Institutions from "./institutions-components/Institutions";
import TabButton from "./TabButton";
import WelcomeSection from "./WelcomeSection";

const Tabs = ({ }) => {
	const [currentTab, setCurrentTab] = useState(0);

	const renderComponent = (number) => {
		switch (number) {
			case 0:
				return <WelcomeSection />;
			case 1:
				return <Institutions />;
			default:
				return null;
		}
	};

	return (
		<>
			<div className="navigation-tabs-container">
				{COMPONENTS_NAMES.map((tab, i) => {
					return (
						<TabButton
							key={i}
							tab={tab}
							index={i}
							currentTab={currentTab}
							setCurrentTab={setCurrentTab}
						/>
					);
				})}
			</div>
			<div className="navigation-content">
				{renderComponent(currentTab)}
			</div>
		</>
	);
};

export default Tabs;
