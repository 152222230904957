import React from "react";

import { ULYSSEUS_INSTITUTIONS_DATA } from "../../../config/metadata";

const Institutions = () => {
	return (
		<>
			<p className="title">Member Institutions</p>
			<div className="institutions-content">
				{ULYSSEUS_INSTITUTIONS_DATA.map((item, index) => {
					return (
						<a
							key={index}
							className="institution"
							href={`/university/${item.slug}`}
						>
							<p>{item.name}</p>
							<img src={item.imgPath} alt={item.name} />
						</a>
					);
				})}
			</div>
		</>
	);
};

export default Institutions;
